import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import CabinetPage from "../../components/Common/CabinetPage";
import MeetingLogContainer from '../../components/Schedule/MeetingLog';
import colors from "../../colors";
import CreateMeetingButton from "../../components/Schedule/CreateMeetingButton/CreateMeetingButton";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";


const ReusableMeetings = (): ReactElement => {
  return (
    <CabinetPage
      pageName={'Meetings'}
      headerContent={<Box width='100%' display='flex' justifyContent='space-between'>
        <Typography variant="h1">
          Reusable Meetings
        </Typography>
        {isLargeFormatDevice() && (
          <CreateMeetingButton buttonTitle="Create" />
        )}
      </Box>
      }
    >
      <Box display="flex" flexDirection="column" height="100%" padding={2}>
        <Typography variant="h2" color={colors.black600} fontWeight="regular">
          Access or update the Reusable Meetings you've prepared.
        </Typography>
        <MeetingLogContainer reusable />
      </Box>
    </CabinetPage>
  );
};

export default ReusableMeetings;
